import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize('G-YRMN7XRR7W', {
	gaOptions: {
		siteSpeedSampleRate: 100,
	},
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals(({id, name, value}) => {
	ReactGA.event({
		category: 'Web Vitals',
		action: name,
		value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
		label: id, // id unique to current page load
		nonInteraction: true, // avoids affecting bounce rate
	});
});
