import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import axios from 'axios';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from 'react-router-dom';

import Home from './home.js';
import Contact from './contact.js';

import contact from './media/email.png';
import instagram from './media/instagram.png';
import twitter from './media/twitter.png';
import logo from './media/lushLogo.png';

import './App.css';

const recordEvent = event => {
	ReactGA.event(event);
};

function App() {
	return (
		<div className="App">
			<Router>
				<header className="App-header">
					<div className="lush-logo-div">
						<Link to="/">
							<img src={logo} className="lush-logo" alt="logo" />
						</Link>
					</div>
					<div className="social-media">
						<a href="https://www.instagram.com/cameron.lush" target="_blank">
							<img src={instagram} className="instagram-logo" alt="instagram logo" />
						</a>
						<a href="https://twitter.com/cameron_lush" target="_blank">
							<img src={twitter} className="twitter-logo" alt="twitter logo" />
						</a>
						<Link to="/contact">
							<img src={contact} className="contact-logo" alt="contact logo" />
						</Link>
					</div>
					<div className="social-media-mobile">
						<a onClick={() => recordEvent({ category: 'Navigation', action: 'Clicked Instagram'})} href="https://www.instagram.com/cameron.lush" target="_blank">
							<img src={instagram} className="instagram-logo" alt="instagram logo" />
						</a>
						<a onClick={() => recordEvent({ category: 'Navigation', action: 'Clicked Twitter'})} href="https://twitter.com/cameron_lush" target="_blank">
							<img src={twitter} className="twitter-logo" alt="twitter logo" />
						</a>
						<Link to="/contact">
							<img src={contact} className="contact-logo" alt="contact logo" />
						</Link>
					</div>
				</header>
				<div>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/contact" element={<Contact />} />
					</Routes>
				</div>
			</Router>
		</div>
	);
}

export default App;
