import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

import './home.css';

function Contact() {
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: "/contact" });
	});

	return (
		<>
			<div className="Contact-body">
				<p>All Inquiries:</p>
				<p>cameronlush97 (at) gmail (dot) com</p>
			</div>
		</>
	);
}

export default Contact;
