import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import axios from 'axios';

import sunny from './media/sunny.png';
import sunsetSessions1 from './media/sunsetSessions1.png';

import './home.css';

const recordEvent = event => {
	ReactGA.event(event);
};

function Homepage() {
	const [email, setEmail] = useState('');
	const [subscribeInfo, setSubscribeInfo] = useState('Subscribe for exclusive updates on music, news, and more!');
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: "/" });
	});

	const onSubscribe = () => {
		recordEvent({
			category: 'Interactions',
			action: 'Subscribed to Emails',
		});
		const timestamp = new Date().getTime();
		const MAILCHIMP_SUBSCRIBE_URL = `https://cameronlush.us20.list-manage.com/subscribe/post-json?
			u=d0cc341662de0e1fb81a27242&id=247f4d2c5a
			&c=jQuery19005469831115303132_1640644912442
			&EMAIL=${encodeURIComponent(email)}
			&b_d0cc341662de0e1fb81a27242_247f4d2c5a=
			&subscribe=Subscribe
			&_=${timestamp}`;

		axios.get(MAILCHIMP_SUBSCRIBE_URL);
		setEmail('');
		setSubscribeInfo('Thank you!');
	};

	return (
		<>
			<div className="Homepage-body">
				<div className="sunset-sessions-vol-1">
					<a target="_blank" onClick={() => recordEvent({ category: 'Navigation', action: 'Clicked Sunset Sessions Vol. 1 - EP'})} href="https://distrokid.com/hyperfollow/cameronlush/sunset-sessions-vol-1">
						<img src={sunsetSessions1} className="music-cover" alt="Sunset Sessions Vol. 1 cover" />
					</a>
					<p className="music-title">
						SUNSET SESSIONS VOL. 1 - EP
					</p>
				</div>

				<div className="sunny">
					<a target="_blank" onClick={() => recordEvent({ category: 'Navigation', action: 'Clicked Sunny - Single'})} href="https://distrokid.com/hyperfollow/cameronlush/sunny">
						<img src={sunny} className="music-cover" alt="Sunny cover" />
					</a>
					<p className="music-title">
						SUNNY - SINGLE
					</p>
				</div>
			</div>

			<footer className="Homepage-footer">
				<div className="subscribe">
					<input type="text" id="email" onChange={e => setEmail(e.target.value)} placeholder="Email Address" value={email} />
					<div className="subscribe-button" onClick={onSubscribe}>
						<span className="subscribe-text">Subscribe</span>
					</div>
				</div>
				<div className="subscribe-info">
					<span className="subscribe-information-text">{subscribeInfo}</span>
				</div>
			</footer>
		</>
	);
}

export default Homepage;
